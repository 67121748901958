// $browser-font-size-viewport-ratio: 14.4;
// $browser-font-size-viewport-ratio-mobile: 3.75;
// @function rem($pixels, $size: $browser-font-size-viewport-ratio) {
//     @return #{$pixels/$size}rem;
// }
// @function mobrem($pixels, $size: $browser-font-size-viewport-ratio-mobile) {
//     @return #{$pixels/$size}rem;
// }

@mixin responsive {
    @media screen and (max-width: calc(#{$wrap-inner-width} + 2*#{$wrap-padding-x})) {
        @content;
    }
}
@mixin maxw1366 {
    @media screen and (max-width: 1366px) {
        @content;
    }
}
@mixin maxw1280 {
    @media screen and (max-width: 1280px) {
        @content;
    }
}
@mixin maxw1200 {
    @media screen and (max-width: 1200px) {
        @content;
    }
}
@mixin not-touch {
    @media screen and (min-width: 1024px) {
        @content;
    }
}
@mixin touch {
    @media screen and (max-width: 1023px) {
        @content;
    }
}
// @mixin mobile {
//     @media screen and (max-width: 767px) {
//         @content;
//     }
// }
@mixin not-mobile {
    @media screen and (min-width: 769px) {
        @content;
    }
}

@mixin maxw639 {
    @media screen and (max-width: 639px) {
        @content;
    }
}
