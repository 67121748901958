body:has(.sales-header) .wrapper:first-of-type {
    padding-top: 186px;
    @include mobile {
        // padding-top: 111px;
        padding-top: 155px;
    }
}
.header-group {
    // position: absolute;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 101;
    background-color: #fff;
}
.sales-header {
    // position: absolute;
    // top: 0;
    // left: 0;
    // right: 0;
    position: relative;
    background-color: $purple-light;
    // @include mobile {
    //     display: none;
    // }
    &:has(.sale-item:only-child) {
        &::before {
            content: initial;
        }
    }
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 50%;
        background-color: rgba(#b281ff, 0.4);
    }
    // + .header {
    //     top: 54px;
    //     @include mobile {
    //         top: 0;
    //     }
    // }
    .container {
        display: flex;
        padding-left: 0;
        padding-right: 0;
    }
    .sale-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 50%;
        padding: 12px 32px;
        @media (max-width: 1280px) {
            padding: 12px 10px;
        }
        @include mobile {
            padding: 6px 0;
            min-height: 52px;
        }
        // &:nth-child(1) {
        //     background-color: rgba(#B281FF, .4);
        // }
        // &:nth-child(2) {
        //     background-color: $purple-light;
        // }
        &:only-child {
            width: 100%;
            justify-content: center;
            .sale-item-link {
                margin-left: 30px;
            }
        }
        &-title {
            font-size: 18px;
            font-weight: 500;
            @include mobile {
                font-size: 16px;
                line-height: 1.2;
            }
            *:is(strong, b, span) {
                font-weight: 700;
            }
        }
        &-descr {
            font-size: 14px;
            font-weight: 400;
            margin-left: 30px;
            @include mobile {
                display: none;
            }
        }
        &-link {
            margin-left: auto;
            padding: 8px 20px;
            border: 1px solid $black;
            display: block;
            font-weight: 400;
            font-size: 14px;
            line-height: normal;
        }
    }
}

.header {
    // position: absolute;
    // z-index: 101;
    // top: 0;
    // left: 0;
    width: 100%;

    @include touch {
        display: none;
    }

    //template reset
    .button {
        background: initial;
        border: 0;
        display: block;
        padding: 0;
        height: initial;
        margin-left: 0;
    }

    // end template reset
    &-top {
        display: flex;
        padding: 13px 0;
        align-items: center;
        justify-content: space-between;

        .columns {
            width: 100%;
        }

        // .left-column {
        //     width: 20%;
        // }

        .right-column {
            // width: 80%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        .logo {
            // width: 202px;
            width: 182px;
            // height: 28px;
            height: 43px;
            color: $black;

            @include maxw1366 {
                // width: 180px;
                width: 146px;
                // height: 26px;
                height: 35px;
            }
        }

        //menu
        .site-menu {
            display: flex;
            align-items: center;
            // margin-right: 40px;
            margin: 0 auto;
            flex-shrink: 1;

            // @include maxw1280 {
            //     margin-right: 24px;
            // }

            &-link {
                line-height: 28px;
                white-space: nowrap;
                color: $grey-text;

                @include maxw1280 {
                    font-size: 11px;
                }

                &:not(:last-child) {
                    margin-right: 30px;

                    @include maxw1366 {
                        margin-right: 24px;
                    }

                    @include maxw1280 {
                        margin-right: 20px;
                    }
                }
                &.is-highlighted,
                &.active {
                    color: $purple;
                }
            }
        }

        //langs
        .langs {
            display: flex;
            // align-items: center;
            align-items: baseline;
            flex-shrink: 0;
        }

        .lang {
            font-size: 12px;
            line-height: 28px;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            color: $grey-text;

            &:not(:last-child) {
                margin-right: 13px;
            }
        }

        .curr-lang {
            color: $black;
            border-bottom: 1px solid currentColor;
        }
    }

    &-bottom {
        background-color: $purple;

        .columns {
            width: 100%;
        }

        .left-column {
            width: 20%;
        }

        .right-column {
            width: 80%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 30px;
            line-height: 28px;
        }

        .overlay {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba($black, 0.3);
            z-index: 1;
            opacity: 0;
            pointer-events: none;
            transition: opacity $duration;
        }

        .control-button {
            border: 0;
            color: inherit;
            padding: 0 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 54px;
            min-width: 50px;
            background-color: transparent;
            position: relative;
            font-size: 14px;
            font-weight: 500;
            color: #fff;
            flex-shrink: 0;

            &:hover {
                background-color: $purple-medium;
            }
        }

        //catalog
        .catalog {
            width: 20%;
            position: relative;

            &.open {
                // z-index: 9999;
                z-index: 10;

                .catalog-overlay {
                    opacity: 1;
                    pointer-events: auto;
                }

                .catalog-button {
                    .drop-arrow-medium {
                        transform: rotate(-180deg);
                    }
                }

                .catalog-dropdown {
                    transform: translateX(0%);
                    pointer-events: auto;
                    opacity: 1;
                }
            }

            &-button {
                // height: 54px;
                background-color: $blue;
                height: 54px;
                padding: 0 15px;
                width: 100%;
                border: 0;
                color: #fff;
                font-size: 16px;
                line-height: 1.75;
                display: flex;
                align-items: center;
                position: relative;
                z-index: 2;

                span {
                    margin-left: 10px;
                    margin-right: auto;
                }

                .drop-arrow-medium {
                    transition: transform $duration;
                }
            }

            &-dropdown {
                background-color: #fff;
                opacity: 0;
                pointer-events: none;
                transition: opacity $duration;
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                z-index: 2;
                min-height: 540px;
                padding-top: 12px;
                padding-bottom: 12px;

                &-types {
                    padding: 0 0 22px;
                    position: relative;

                    &::after {
                        display: block;
                        content: '';
                        position: absolute;
                        bottom: 11px;
                        left: 16px;
                        right: 16px;
                        border-bottom: 2px solid $grey-border;
                        pointer-events: none;
                    }
                }

                &-type {
                    padding: 4px 16px;
                    line-height: 28px;
                    color: $black;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    transition: color $duration, background-color $duration;
                    text-transform: uppercase;

                    &:hover {
                        color: $purple;
                        background-color: $purple-ultralight;
                    }
                }

                hr {
                    margin: 8px;
                }

                &-category {
                    &:hover {
                        .catalog-dropdown-category-list {
                            display: flex;
                        }

                        .catalog-dropdown-category-title {
                            color: $purple;
                            background-color: $purple-ultralight;

                            .catalog-link-arrow {
                                display: block;
                            }

                            &.is-arrowless {
                                .catalog-link-arrow {
                                    display: none;
                                }
                            }
                        }
                    }

                    &-title {
                        padding: 9px 16px;
                        color: $black;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        transition: color $duration, background-color $duration;

                        span {
                            line-height: 20px;
                        }

                        &.has-text-link {
                            color: $purple !important;
                            background-color: $purple-ultralight;
                        }

                        .catalog-link-arrow {
                            color: $purple;
                        }
                    }

                    &-list {
                        display: none;
                        align-items: flex-start;
                        position: absolute;
                        left: 100%;
                        top: 0;
                        bottom: 0;
                        width: calc(100vw - 100%);
                        max-width: 730px;
                        background-color: #fff;
                        border-left: 2px solid $grey-border;
                        // overflow: hidden;
                        overflow-y: auto;
                    }

                    &-links {
                        // columns: 2;
                        // column-gap: 15px;
                        // column-fill: auto;
                        // overflow-y: auto;
                        // padding: 14px;
                        padding: 12px 0;
                        align-self: stretch;
                        // width: 100%;
                        // max-width: 593px;
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        width: 100%;
                        max-width: 285px;

                        &.is-columns {
                            columns: 2;
                            column-fill: auto;
                            display: block;
                        }
                    }

                    &-link {
                        font-weight: 400;
                        line-height: 1.57;
                        display: block;
                        white-space: break-spaces;
                        break-inside: avoid;
                        padding-left: 16px;

                        &:not(:last-child) {
                            margin-bottom: 9px;
                        }

                        small {
                            font-size: inherit;
                            color: $grey-text;
                            margin-left: 3px;
                        }
                    }

                    // &-banner {
                    //     padding: 20px;
                    //     width: 300px;
                    //     flex-shrink: 0;
                    // }
                    @include desktop {
                        .nested.dropdown {
                            position: static;
                            width: 100%;
                            max-width: 285px;

                            &:hover {
                                .dropdown-trigger {
                                    .navbar-item {
                                        background-color: $purple-ultralight;
                                        color: $purple;
                                    }
                                }
                            }

                            .dropdown-trigger {
                                position: relative;

                                &::before {
                                    display: block;
                                    content: '';
                                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 8' fill='none' id='link-arrow'%3E%3Cpath d='M5.34359e-07 7.05666L3.09171 4L7.14338e-08 0.943344L0.95413 -7.18185e-08L5 4L0.95413 8L5.34359e-07 7.05666Z' fill='%239E61FF'%3E%3C/path%3E%3C/svg%3E");
                                    position: absolute;
                                    width: 5px;
                                    height: 8px;
                                    top: calc(50% - 4px);
                                    right: 16px;
                                    z-index: 2;
                                }

                                .navbar-item {
                                    padding: 4px 16px;
                                    line-height: 28px;
                                    font-weight: 400;
                                    color: inherit;
                                }
                            }

                            .dropdown-menu {
                                top: 0;
                                bottom: 0;
                                border-left: 2px solid $grey-border;
                                width: 100%;
                                padding: 0;
                            }

                            .dropdown-content {
                                padding: 12px 0;
                                box-shadow: initial;
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;

                                .navbar-item {
                                    padding: 0;
                                    font-weight: 400;
                                    line-height: 1.57;
                                    display: block;
                                    white-space: break-spaces;
                                    padding-left: 16px;
                                    color: inherit;

                                    &:not(:last-child) {
                                        margin-bottom: 9px;
                                    }

                                    &:hover {
                                        color: $purple;
                                        background-color: initial;
                                    }
                                }
                            }
                        }
                    }
                }

                &-download {
                    margin-top: 24px;
                    padding: 4px 16px;
                    line-height: 28px;
                    color: $purple;
                    display: flex;
                    align-items: center;
                    transition: color $duration, background-color $duration;
                    text-transform: uppercase;

                    &:hover {
                        color: $purple;
                        background-color: $purple-ultralight;
                    }

                    span {
                        margin-left: 5px;
                    }
                }
            }
        }

        // end catalog

        //search
        $search-wrapper-padding-left: 30px;

        .search-wrapper {
            margin-right: 55px !important;
            position: relative;
            padding: 7px 0 7px $search-wrapper-padding-left;
            max-width: 633px;

            @include maxw1366 {
                margin-right: 30px !important;
            }

            @include maxw1280 {
                padding-left: 16px;
                margin-right: 16px !important;
            }

            // &.open {
            //     // z-index: 9999;
            //     z-index: 10;
            //     .search-overlay {
            //         opacity: 1;
            //         pointer-events: auto;
            //     }
            //     .search-results {
            //         opacity: 1;
            //         pointer-events: auto;
            //     }
            // }
            .simplebar-content {
                padding: 0 !important;
            }

            .dropdown-content {
                padding: 0;
                border-radius: 0;
                box-shadow: initial;
            }

            .dropdown {
                // margin-right: 65px;
                margin-right: 55px;
                position: relative;
                padding: 7px 0 7px 8px;

                &.is-active {
                    // z-index: 9999;
                    z-index: 10;

                    .search-overlay {
                        opacity: 1;
                        pointer-events: auto;
                    }

                    .dropdown-menu {
                        opacity: 1;
                        pointer-events: auto;
                    }
                }
            }

            .dropdown-item {
                padding: 0;
                color: initial;
                line-height: initial;
                font-size: initial;
            }

            // .search-form {
            .dropdown-trigger {
                position: relative;
                z-index: 2;
            }

            // .search-input {
            .input {
                display: block;
                width: 100%;
                height: 40px;
                border: 0;
                background-color: #fff;
                padding-left: 15px;
                padding-right: 40px;
                padding-top: 1px;
                padding-bottom: 0;
                font-size: 15px;
                font-weight: 400;
                color: $grey-text;
                box-shadow: initial;
                border-radius: 0;
                // &-clear {
                //     position: absolute;
                //     top: 0;
                //     bottom: 0;
                //     right: 40px;
                //     width: 40px;
                //     color: $grey-text;
                //     display: flex;
                //     align-items: center;
                //     border: 0;
                //     background-color: transparent;
                //     display: flex;
                //     align-items: center;
                //     justify-content: center;
                // }
            }

            .cart-change-quantity {
                // flex-shrink: 0;
                // display: flex;
                // width: 84px;
                // height: 44px;
                // border: 1px solid $grey-border;
                // position: relative;
                margin-right: 12px;

                // button {
                //     color: rgba($black, .7);
                //     width: 30px;
                //     position: absolute;
                //     top: 0;
                //     bottom: 0;
                //     z-index: 2;
                //     display: flex;
                //     align-items: center;
                //     justify-content: center;
                //     cursor: pointer;
                //     font-size: 18px;
                //     font-weight: 400;
                //     padding: 0;
                //     border: 0;
                //     background: transparent;
                //     height: auto;
                //     &:first-child {
                //         left: 0;
                //     }
                //     &:last-child {
                //         right: 0;
                //     }
                //     &:focus {
                //         outline: none;
                //         box-shadow: initial;
                //     }
                // }
                // input {
                //     line-height: 42px;
                //     appearance: textfield;
                //     color: $black;
                //     border: 0;
                //     text-align: center;
                //     font-weight: 400;
                //     display: block;
                //     width: 100%;
                //     height: 100%;
                //     box-shadow: initial;
                //     border-radius: 0;
                //     padding: 0 15px;
                //     &::-webkit-outer-spin-button,
                //     &::-webkit-inner-spin-button {
                //         -webkit-appearance: none;
                //         margin: 0;
                //     }
                // }
                .input {
                    padding-right: 15px;
                }
            }

            // .search-button {
            span.icon.is-small.is-right {
                width: 40px;
                height: 40px;
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 0;
                background-color: transparent;
                color: $black;

                .icon {
                    position: initial;
                    color: inherit;
                }
            }

            .dropdown-menu,
            .search-results {
                background-color: #fff;
                opacity: 0;
                pointer-events: none;
                transition: opacity $duration;
                position: absolute;
                top: calc(100% - 7px);
                left: 8px;
                right: 0;
                width: initial;
                z-index: 2;
                border-top: 2px solid $grey-border;
                padding-top: 0;
            }

            .no-results {
                padding: 10px 15px;
            }

            .history-list {
                padding: 10px 0 15px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                &-title {
                    color: $grey-text;
                    font-size: 12px;
                    text-transform: uppercase;
                    line-height: 2.33;
                    // padding: 0 15px;
                }

                &-items {
                    width: 100%;
                }

                .dropdown-item {
                    padding: 0 15px;

                    &:not(:last-child) {
                        margin-bottom: 5px;
                    }

                    a {
                        font-size: 15px;
                        line-height: 1.87;
                        transition: color $duration;

                        &:hover {
                            color: $purple;
                        }
                    }
                }

                // &-item {
                //     padding: 10px 15px;
                //     position: relative;
                //     cursor: pointer;
                //     background-color: transparent;
                //     transition: background-color $duration;
                //     &:hover {
                //         background-color: $purple-light;
                //         .history-list-item-title {
                //             color: $purple;
                //         }
                //         .history-list-item-amount {
                //             color: $purple-medium;
                //         }
                //         .history-list-item-remove {
                //             opacity: 1;
                //             pointer-events: auto;
                //         }
                //     }
                //     &-title {
                //         font-size: 15px;
                //         line-height: 1.87;
                //         transition: color $duration;
                //     }
                //     &-amount {
                //         margin-top: 3px;
                //         font-size: 15px;
                //         line-height: 1.87;
                //         color: $grey-text;
                //         transition: color $duration;
                //     }
                //     &-remove {
                //         color: $purple;
                //         width: 12px;
                //         height: 12px;
                //         // cursor: pointer;
                //         position: absolute;
                //         top: 13px;
                //         right: 13px;
                //         opacity: 0;
                //         pointer-events: none;
                //         transition: opacity $duration;
                //     }
                // }
                &-clear {
                    margin-top: 5px;
                    // cursor: pointer;
                    color: $purple;
                    // font-size: 15px;
                    // line-height: 1.87;
                    // border: 0;
                    // background-color: transparent;
                    // margin-left: 15px;
                }
            }

            .results-list {
                // padding-top: 12px;
                // &:last-child {
                //     padding-bottom: 20px;
                // }
                &-category {
                    padding-top: 12px;

                    &:last-child {
                        padding-bottom: 20px;
                    }

                    .dropdown-item {
                        padding: 10px 15px;
                        position: relative;
                        // cursor: pointer;
                        background-color: transparent;
                        transition: background-color $duration;

                        &:hover {
                            background-color: $purple-light;

                            div {
                                color: $purple;

                                &:nth-child(2) {
                                    color: $purple-medium;
                                }
                            }
                        }

                        div {
                            font-size: 15px;
                            line-height: 1.87;
                            transition: color $duration;

                            &:nth-child(2) {
                                font-weight: 400;
                                margin-top: 3px;
                                color: $grey-text;
                            }
                        }
                    }
                }

                // &-item {
                //     padding: 10px 15px;
                //     position: relative;
                //     cursor: pointer;
                //     background-color: transparent;
                //     transition: background-color $duration;
                //     &:hover {
                //         background-color: $purple-light;
                //         .results-list-item-title {
                //             color: $purple;
                //         }
                //         .results-list-item-amount {
                //             color: $purple-medium;
                //         }
                //     }
                //     &-title {
                //         font-size: 15px;
                //         line-height: 1.87;
                //         transition: color $duration;
                //     }
                //     &-amount {
                //         margin-top: 3px;
                //         font-size: 15px;
                //         line-height: 1.87;
                //         color: $grey-text;
                //         transition: color $duration;
                //     }
                // }
                &-total {
                    margin-top: 5px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    background-color: $grey-bg;
                    padding: 10px 15px;
                    font-size: 15px;
                    line-height: 1.87;
                    cursor: pointer;

                    // &-link {
                    //     margin-top: 3px;
                    //     color: $purple;
                    // }
                    .mt-2,
                    .mb-3 {
                        margin: 0 !important;
                    }

                    a {
                        margin-top: 3px;
                        color: $purple;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 1.87;
                    }
                }

                &-clear {
                    margin-top: 5px;
                    cursor: pointer;
                    color: $purple;
                    font-size: 15px;
                    line-height: 1.87;
                    border: 0;
                    background-color: transparent;
                    margin-left: 15px;
                }
            }

            .products-list {
                padding-top: 10px;

                // padding-left: 15px;
                // padding-right: 15px;
                &:last-child {
                    padding-bottom: 20px;
                }

                &.dealer-products-list {
                    .products-list-item {
                        .current-price {
                            color: $orange;
                        }
                    }
                }

                .dropdown-item {
                    &:not(:last-child) {
                        margin-bottom: 15px;
                    }
                }

                &-title {
                    font-size: 12px;
                    line-height: 2.33;
                    color: $grey-text;
                    text-transform: uppercase;
                    padding: 0 15px;
                }

                &-item {
                    display: flex;
                    align-items: center;
                    padding: 7.5px 15px;
                    transition: background-color $duration;

                    &:hover {
                        background-color: $purple-light;
                    }

                    // &:not(:last-child) {
                    //     margin-bottom: 15px;
                    // }
                    &-descr {
                        display: flex;
                        flex-wrap: wrap;
                        flex-grow: 1;
                        flex-shrink: 1;
                    }

                    &-title {
                        width: 100%;
                        font-size: 15px;
                        line-height: 1.1;
                        margin-bottom: 4px;
                    }

                    &-number {
                        font-size: 11px;
                        line-height: 14px;
                        color: $grey-text;
                        font-weight: 400;
                        margin-right: 8px;

                        &:empty {
                            display: none;
                        }
                    }

                    .out-wrapper {
                        flex: none;
                        width: 100%;
                        margin-top: 3px;
                    }

                    .out-of-production {
                        color: $grey-text;
                        font-size: 12px;
                        line-height: 20px;
                        padding: 0 8px;
                        background-color: $grey-bg;
                        font-weight: 400;
                        display: inline-block;
                        align-self: flex-start;

                        @include mobile {
                            margin-top: 6px;
                            font-size: 10px;
                            line-height: 12px;
                            padding: 0;
                            display: block;
                        }
                    }

                    &-stock {
                        font-size: 11px;
                        line-height: 14px;
                        font-weight: 400;

                        &.available {
                            color: $green;
                        }

                        &.out-of-stock {
                            color: red;
                        }
                    }

                    &-prices {
                        width: 105px;
                        flex-shrink: 0;
                    }

                    .old-price {
                        text-decoration: line-through;
                        font-size: 12px;
                        line-height: 1.38;
                        color: $grey-text;
                        // font-weight: 400;
                    }

                    .current-price {
                        font-size: 24px;
                        line-height: 0.69;
                        display: flex;
                        align-items: baseline;

                        &.color-dealer {
                            color: $orange;
                        }

                        &:not(:first-child) {
                            margin-top: 5px;
                        }

                        span {
                            font-size: 12px;
                            font-weight: 400;
                            margin-left: 5px;
                        }
                    }

                    .regular-price,
                    .dealer-price {
                        // margin-top: 5px;
                        font-size: 12px;
                        line-height: 1.38;

                        // font-weight: 400;
                        &:not(:first-child) {
                            margin-top: 5px;
                        }
                    }

                    // &-quantity {
                    //     flex-shrink: 0;
                    //     display: flex;
                    //     width: 84px;
                    //     height: 44px;
                    //     border: 1px solid $grey-border;
                    //     position: relative;
                    //     margin-right: 12px;
                    //     span {
                    //         color: rgba($black, .7);
                    //         width: 30px;
                    //         position: absolute;
                    //         top: 0;
                    //         bottom: 0;
                    //         z-index: 2;
                    //         display: flex;
                    //         align-items: center;
                    //         justify-content: center;
                    //         cursor: pointer;
                    //         &:first-child {
                    //             left: 0;
                    //         }
                    //         &:last-child {
                    //             right: 0;
                    //         }
                    //     }
                    //     input {
                    //         line-height: 42px;
                    //         appearance: textfield;
                    //         color: $black;
                    //         border: 0;
                    //         text-align: center;
                    //         font-weight: 400;
                    //         display: block;
                    //         width: 100%;
                    //     }
                    // }
                    .simplebar-content {
                        padding: 10px 0 20px !important;
                    }

                    .column {
                        padding: 0;
                        flex-shrink: 0;
                        flex: initial;
                        flex-shrink: 0;

                        .columns {
                            margin-left: 0;
                            margin-right: 0;
                            margin-top: 0;
                            margin-bottom: 0;
                        }
                    }

                    .button.is-link.is-small,
                    &-buy {
                        flex-shrink: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 105px;
                        height: 44px;
                        border: 0;
                        background-color: $purple;
                        color: #fff;

                        span {
                            margin-left: 6px;
                            font-weight: 400;
                        }

                        .icon {
                            margin-left: 0;
                            margin-right: 0;
                        }
                    }
                }
            }
        }

        // end search

        // help
        .help-group {
            margin-right: 0px !important;

            @include maxw1280 {
                margin-right: 0 !important;
            }

            // position: relative;
            // &.open {
            //     .help-dropdown {
            //         opacity: 1;
            //         pointer-events: auto;
            //     }
            //     .help-button {
            //         .drop-arrow {
            //             transform: rotate(-180deg);
            //         }
            //     }
            // }
            .dropdown-menu {
                padding-top: 0;
                // top: calc(100% - 10px);
                top: 100%;
                right: -5px;
                left: initial;
                width: 260px;
                box-shadow: 0px 1px 5px rgba(34, 34, 34, 0.15);

                &::before {
                    display: block;
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 6px 6px 6px;
                    border-color: transparent transparent #fff transparent;
                    position: absolute;
                    bottom: 100%;
                    right: 17px;
                }
            }

            .dropdown-content {
                padding: 7px 0;
            }
        }

        .help-button {
            span {
                margin-left: 3px;
                margin-right: 3px;
            }

            // .drop-arrow {
            //     transition: transform $duration;
            // }
        }

        .help-dropdown {
            position: absolute;
            right: -6px;
            top: calc(100% - 10px);
            display: flex;
            flex-direction: column;
            background-color: #fff;
            min-width: 260px;
            // padding: 10px 0;
            opacity: 0;
            pointer-events: none;
            transition: opacity $duration;

            &::before {
                display: block;
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 7px 7px 7px;
                border-color: transparent transparent #fff transparent;
                position: absolute;
                bottom: 100%;
                right: 13px;
            }
        }

        .help-link {
            padding: 10px 0;
            display: flex;
            align-items: center;
            padding-left: 15px;
            line-height: 1.4;
            color: $black;
            transition: color $duration, background-color $duration;
            min-width: 200px;

            &:hover {
                background-color: $purple-ultralight;
                color: $purple;

                .icon {
                    color: $purple;
                }
            }

            .icon {
                color: $grey-text;
            }

            span {
                margin-left: 8px;
            }
        }

        // end help

        // account
        .auth-user {
            .dropdown-menu {
                padding-top: 0;
                // top: calc(100% - 10px);
                top: 100%;
                right: -5px;
                left: initial;
                width: 260px;
                box-shadow: 0px 1px 5px rgba(34, 34, 34, 0.15);

                &::before {
                    display: block;
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 6px 6px 6px;
                    border-color: transparent transparent #fff transparent;
                    position: absolute;
                    bottom: 100%;
                    right: 17px;
                }
            }

            .dropdown-content {
                padding: 7px 0;
            }

            .dropdown-item {
                padding: 7.5px 0;
                display: flex;
                align-items: center;
                padding-left: 15px;
                line-height: 1.4;
                color: $black;
                transition: color $duration, background-color $duration;
                width: 100%;

                &:hover {
                    background-color: $purple-ultralight;
                    color: $purple;

                    .icon {
                        color: $purple;
                    }
                }

                .icon {
                    color: $grey-text;
                    width: 16px;
                }

                span {
                    margin-left: 8px;
                    margin-bottom: -3px;
                }

                .badge {
                    margin-left: 0px;
                    border-radius: 100%;
                    padding: 5px 2px;
                    font-size: 9px;
                    line-height: 1.1;
                    background-color: #9e61ff;
                    font-weight: bold;
                    color: #fff;
                    min-width: 19px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .account-button {
            max-width: 130px;
            min-width: 130px;

            svg {
                margin-left: 0 !important;
                margin-right: 0 !important;
            }

            span {
                margin-left: 6px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: calc(100% - 19px);
                text-align: left;
            }
        }

        // end account

        // compare
        .compare-button {
            span {
                position: absolute;
                top: 8px;
                right: 4px;
                width: 17px;
                height: 17px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $purple-medium;
                font-size: 12px;
                line-height: 1.1;
            }
        }

        .shop-compare {
            .dropdown-menu {
                padding-top: 0;
                // top: calc(100% - 10px);
                top: 100%;
                right: -5px;
                left: initial;
                width: 260px;
                box-shadow: 0px 1px 5px rgba(34, 34, 34, 0.15);

                &::before {
                    display: block;
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 6px 6px 6px;
                    border-color: transparent transparent #fff transparent;
                    position: absolute;
                    bottom: 100%;
                    right: 17px;
                }
            }

            .dropdown-content {
                padding: 7px 0;

                > .dropdown-item {
                    font-size: 12px;
                    text-transform: uppercase;
                    padding: 0 15px;
                    color: $grey-text;
                    margin-bottom: 5px;
                }
            }

            .dropdown-divider {
                display: none;
            }

            .columns {
                position: relative;

                &:hover {
                    background-color: $purple-light;

                    .column {
                        &.is-narrow {
                            opacity: 1;
                            pointer-events: auto;
                        }
                    }
                }

                .column {
                    min-height: 39px;

                    &:first-child {
                        padding: 9px 15px !important;
                        padding-right: 39px !important;
                        font-size: 14px;
                        line-height: 1.4;
                    }

                    &.is-narrow {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 39px;
                        height: 39px;
                        opacity: 0;
                        pointer-events: none;

                        .mr-2 {
                            margin-right: 0 !important;
                            display: block;
                            width: 39px;
                            height: 39px;
                        }

                        .icon {
                            width: 39px;
                            height: 39px;
                            background: transparent url("data:image/svg+xml,%3Csvg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.4648 4.24219L4.97956 12.7275' stroke='%239E61FF' stroke-width='1.5'/%3E%3Cpath d='M4.98047 4.24219L13.4658 12.7275' stroke='%239E61FF' stroke-width='1.5'/%3E%3C/svg%3E%0A") center no-repeat;
                            background-size: 18px 17px;
                            transform: initial !important;
                        }
                    }
                }

                a {
                    padding: 0;
                    color: $black;
                    white-space: initial;

                    &:hover {
                        background-color: initial;
                    }
                }

                .badge {
                    font-size: 14px;
                    line-height: 1.4;
                    color: $grey-text;
                    // margin-left: 4px;
                }
            }
        }

        // end compare

        // wishlist
        .wishlist-button {
            margin-right: 10px;

            span {
                position: absolute;
                top: 8px;
                right: 4px;
                width: 17px;
                height: 17px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $purple-medium;
                font-size: 12px;
                line-height: 1.1;
            }
        }

        // end wishlist

        // cart
        .cart {
            position: relative;

            .dropdown-menu {
                padding-top: 0;
                // top: calc(100% - 10px);
                top: 100%;
                right: -5px;
                left: initial;
                // width: 260px;
                width: 274px;
                box-shadow: 0px 1px 5px rgba(34, 34, 34, 0.15);

                &::before {
                    display: block;
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 6px 6px 6px;
                    border-color: transparent transparent #fff transparent;
                    position: absolute;
                    bottom: 100%;
                    right: 17px;
                }
            }

            .dropdown-item {
                padding: 0;
                color: inherit;
                font-size: inherit;
                line-height: inherit;

                &:first-child {
                    padding: 0;
                    font-size: 18px;
                    line-height: 1.56;
                    color: $black;
                    font-weight: 500;
                }

                &:last-child {
                    margin-top: 8px;
                }

                .columns {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    margin: 0;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .column {
                    padding: 0;
                }

                a {
                    font-size: 14px;
                    line-height: 2;
                    color: $purple;
                }

                .button.is-link {
                    height: 44px;
                    color: #fff;
                    border-radius: 0;
                    background-color: $purple;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                }
            }

            .dropdown-content {
                width: 100%;
                padding: 15px;
                border-radius: 0;
            }

            .dropdown-divider {
                margin: 10px 0;
                height: 2px;
                background-color: $grey-border;
            }

            .cart-empty {
                &-title {
                    font-size: 16px;
                    line-height: 1.4;
                }

                &-text {
                    margin-top: 6px;
                    font-size: 14px;
                    line-height: 1.4;
                    color: $grey;

                    a {
                        color: $purple;
                    }
                }
            }

            .cart-wrapper + .dropdown-item {
                border-top: 2px solid $grey-border;
                padding-top: 10px;
                // padding-bottom: 10px;
                font-size: 18px;
                line-height: 28px;
            }

            &-title {
                display: none;
            }
            &-body {
                &-title {
                    font-size: 16px;
                    line-height: 1.3;
                    font-weight: 500;
                    padding-bottom: 10px;
                    border-bottom: 2px solid $grey-border;
                    span {
                        span {
                            color: $grey-text;
                            margin-left: 4px;
                        }
                    }
                }
                &-controls {
                    display: none;
                }
            }

            .block-title {
                display: none;
            }

            &-products {
                // max-height: 480px;
                max-height: 390px;
                overflow-y: auto;
                display: block;
                margin-right: -10px;
                padding-right: 10px;
                thead {
                    display: none;
                }
                tr {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-between;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    &:not(:last-child) {
                        border-bottom: 2px solid $grey-border;
                    }
                }
                td {
                    &:first-child {
                        width: 100%;
                        padding-bottom: 10px;
                    }
                    &:nth-child(2) {
                        display: none;
                    }
                    &:nth-child(3) {
                        padding-left: 48px;
                    }
                }
                .product-wrapper {
                    display: flex;
                    align-items: flex-end;
                }
                .product-image {
                    align-self: flex-start;
                    flex-shrink: 0;
                    display: flex;
                    width: 40px;
                    margin-right: 8px;
                }
                .product-category {
                    display: none;
                }
                .product-name {
                    font-size: 12px;
                    line-height: 1.4;
                    font-weight: 500;
                    span {
                        line-height: 8px;
                        padding: 3px;
                        margin-left: 6px;
                        display: inline-block;
                        background-color: rgba(#FFBF17, .15);
                        font-weight: 400;
                    }
                }
                .product-remove {
                    color: $grey-text;
                    margin-left: 8px;
                    flex-shrink: 0;
                    display: block;
                    border: 0;
                    background-color: transparent;
                    &.mobile-hidden {
                        display: none;
                    }
                }
                .icon.cart {
                    width: 24px;
                    height: 24px;
                    display: block;
                }
                .cell-title-mobile {
                    display: none;
                }
            }

            &-change-quantity {
                height: 36px;
            }
        }

        .cart-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 58px;
            height: 54px;
            font-size: 12px;
            line-height: 1.1;
            background-color: $purple-medium;

            &.has-items {
                color: $purple;
                background-color: $purple-light;
            }

            span {
                margin-left: 4px;
            }
        }

        // end cart
    }

    .auth-user {
        .tabs {
            display: none;
        }
    }

    .icon {
        color: inherit;
        transition: color $duration;

        &.catalog {
            width: 19px;
            height: 18px;
        }

        &.drop-arrow-medium {
            width: 12px;
            height: 7px;
        }

        &.search {
            width: 19px;
            height: 19px;
        }

        &.phone {
            width: 19px;
            height: 19px;
        }

        &.user {
            width: 18px;
            height: 19px;
        }

        &.compare {
            width: 18px;
            height: 18px;
        }

        &.wishlist {
            width: 13px;
            height: 18px;
        }

        &.cart {
            width: 20px;
            height: 18px;
        }

        &.drop-arrow {
            width: 8px;
            height: 5px;
        }

        &.mail {
            width: 18px;
            height: 14px;
        }

        &.chat {
            width: 18px;
            height: 18px;
        }

        &.messenger {
            width: 18px;
            height: 20px;
        }

        &.catalog-link-arrow {
            width: 5px;
            height: 8px;
        }

        &.clear {
            width: 13px;
            height: 13px;
        }

        &.remove {
            width: 8px;
            height: 8px;
        }

        &.plus {
            width: 11px;
            height: 10px;
        }

        &.minus {
            width: 11px;
            height: 2px;
        }

        &.fb-btn {
            width: 11px;
            height: 20px;
        }

        &.google-btn {
            width: 20px;
            height: 20px;
        }

        &.password-eye {
            width: 16px;
            height: 12px;
        }

        &.close {
            width: 12px;
            height: 12px;
        }

        &.download {
            width: 14px;
            height: 14px;
        }
    }

    + .block {
        margin-top: 20px;
    }
}

.header-touch {
    display: none;
    // position: absolute;
    // top: 0;
    // left: 0;
    // width: 100%;
    // z-index: 1000;

    @include touch {
        display: block;
    }

    .header-top {
        padding-top: 16px;
        padding-bottom: 16px;
        background-color: #fff;
    }

    .header-bottom {
        background-color: $purple;
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .logo {
        width: 146px;
        // height: 21px;
        height: 35px;
    }

    svg {
        display: block;
    }

    .button {
        padding: 0;
        border: 0;
        background-color: transparent;
    }

    .controls {
        display: flex;
        align-items: center;

        > * {
            &:not(:last-child) {
                margin-right: 20px;
            }
        }

        .button {
            width: 24px;
            height: 24px;
        }
    }

    .icon {
        &:first-child:not(:last-child) {
            margin: 0;
        }
    }

    .header-top {
        .shop-compare {
            .dropdown-menu {
                //display: none !important;

                .columns {
                    display: flex;

                    .column {
                        &.is-narrow {
                            flex: none;
                            width: unset;

                            .icon {
                                width: 39px;
                                height: 39px;
                                background: transparent url("data:image/svg+xml,%3Csvg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.4648 4.24219L4.97956 12.7275' stroke='%239E61FF' stroke-width='1.5'/%3E%3Cpath d='M4.98047 4.24219L13.4658 12.7275' stroke='%239E61FF' stroke-width='1.5'/%3E%3C/svg%3E%0A") center no-repeat;
                                background-size: 18px 17px;
                                transform: initial !important;
                            }
                        }
                    }
                }
            }
        }

        .control-button {
            &.wishlist-button,
            &.compare-button {
                position: relative;

                span {
                    position: absolute;
                    top: -6px;
                    right: -13px;
                    color: #fff;
                    width: 17px;
                    height: 17px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #b98cff;
                    font-size: 12px;
                    line-height: 1.1;
                }
            }
        }
    }

    .auth-user {
        .dropdown-trigger {
            .button {
                width: auto;
            }
        }

        .dropdown-menu {
            display: none !important;
        }

        .account-button {
            display: flex;
            align-items: center;

            span {
                margin-left: 6px;
                max-width: 85px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 14px;
            }
        }

        .account-button {
            .icon {
                width: 17px;
                height: 18px;
            }
        }

        .dropdown-item {
            display: flex;
            align-items: center;
            padding-right: 1rem;

            .icon {
                margin-right: 10px !important;
            }

            span {
                padding-top: 2px;
            }
        }
    }

    .shop-compare {
        .icon {
            width: 18px;
            height: 18px;
        }
    }

    .wishlist-button {
        .icon {
            width: 12px;
            height: 18px;
        }
    }

    .burger {
        border: 0;
        border-radius: 0;
        width: 44px;
        height: 44px;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $blue;

        span {
            width: 20px;
            height: 2px;
            display: block;
            background-color: #fff;

            &:not(:last-child) {
                margin-bottom: 5px;
            }
        }
    }

    .shop-search {
        width: 100%;
        margin: 0 10px;

        .input {
            height: 36px;
            box-shadow: initial;
            border-radius: 0;
            // color: rgba($black, .5);
            color: $black;
            border: 0;
            padding: 0 32px 0 16px;
            font-size: 15px;
            font-weight: 500;

            &::placeholder {
                color: rgba($black, 0.5);
            }
        }

        span.icon {
            display: block;
            position: absolute;
            top: 9px;
            right: 16px !important;
            width: 19px;
            height: 19px;
        }

        svg.icon {
            color: $black;
            width: 19px;
            height: 19px;
            position: static;
        }

        .dropdown {
            &.is-active {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #fff;
                z-index: 100;
                display: block;
                max-height: 100vh;
                max-height: -webkit-fill-available;

                .overlay {
                    height: 48px;
                    color: #fff;
                    padding: 0 16px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 15px;
                    position: relative;
                    background-color: $blue;
                    opacity: 1;
                    pointer-events: auto;

                    &::before {
                        content: 'Поиск';
                        font-size: 15px;
                    }

                    &::after {
                        display: block;
                        content: '';
                        background-image: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 10.2029L2.67161 6L7 1.7971L5.66422 0.5L7.1725e-07 6L5.66422 11.5L7 10.2029Z' fill='white'/%3E%3C/svg%3E%0A");
                        width: 7px;
                        height: 12px;
                    }
                }

                .dropdown-trigger {
                    padding: 10px 10px 0;
                }

                .input {
                    height: 44px;
                    background-color: $grey-bg;
                }

                span.icon {
                    top: 12px;
                }

                .cart-change-quantity {
                    .input {
                        background-color: #fff;
                    }
                }

                .dropdown-menu {
                    position: relative;
                    margin-top: 18px;
                    top: initial;
                    padding-top: 0;
                    min-width: initial;
                    overflow-y: auto;
                    max-height: calc(100vh - 48px - 54px - 18px); //48button 54search 18marign
                }

                .dropdown-content {
                    box-shadow: none;
                    padding: 0;
                    border-radius: 0;
                    max-height: 100% !important;
                }
            }
        }
    }

    .history-list {
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        line-height: 1.4;

        .dropdown-item {
            padding: 12px 0;
            border-bottom: 2px solid $grey-border;
            color: $black;
        }

        .history-list-title {
            // font-size: 14px;
            // line-height: 1.4;
            color: $grey-text;
            text-transform: uppercase;
            margin-bottom: 9px;
            padding: 0;
            border: 0;
        }

        .history-list-clear {
            margin-top: 23px;
            color: $purple;
            margin-left: auto;
            // font-size: 14px;
            // line-height: 1.4;
            text-transform: uppercase;
            padding: 0;
            border: 0;
        }
    }

    .results-list {
        &-category {
            padding-top: 0;

            &:last-child {
                padding-bottom: 20px;
            }

            .dropdown-item {
                padding: 6px 10px;
                position: relative;
                // cursor: pointer;
                background-color: transparent;
                transition: background-color $duration;

                &:first-child {
                    padding-top: 0;
                }

                div {
                    font-size: 14px;
                    line-height: 1.4;

                    &:nth-child(2) {
                        font-weight: 400;
                        margin-top: 8px;
                        color: $grey-text;
                    }
                }
            }
        }

        &-total {
            margin-top: 6px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background-color: $purple-light;
            padding: 10px;
            font-size: 14px;
            line-height: 1.4;
            cursor: pointer;

            // &-link {
            //     margin-top: 3px;
            //     color: $purple;
            // }
            .dropdown-item {
                padding: 0;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            .mt-2,
            .mb-3 {
                margin: 0 !important;
            }

            a {
                margin-top: 12px;
                color: $purple;
                // font-weight: 400;
                font-size: 16px;
            }
        }

        &-clear {
            margin-top: 5px;
            cursor: pointer;
            color: $purple;
            font-size: 15px;
            line-height: 1.87;
            border: 0;
            background-color: transparent;
            margin-left: 15px;
        }
    }

    .products-list {
        padding: 16px 10px 0;

        // padding-left: 15px;
        // padding-right: 15px;
        &:last-child {
            padding-bottom: 20px;
        }

        &.dealer-products-list {
            .products-list-item {
                .current-price {
                    color: $orange;
                }
            }
        }

        .dropdown-item {
            padding: 0;
            color: inherit;

            &:not(:first-child) {
                padding-top: 16px;
            }

            &:not(:last-child) {
                padding-bottom: 16px;
                border-bottom: 2px solid $grey-border;
                // margin-bottom: 16px;
            }
        }

        &-title {
            font-size: 14px;
            line-height: 1.4;
            color: $grey-text;
            text-transform: uppercase;
        }

        &-item {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 0;

            &-descr {
                display: flex;
                flex-wrap: wrap;
                flex-grow: 1;
                flex-shrink: 1;
            }

            &-title {
                width: 100%;
                font-size: 15px;
                line-height: 1;
                margin-bottom: 12px;
            }

            &-number {
                font-size: 11px;
                line-height: 14px;
                color: $grey-text;
                font-weight: 400;
                margin-right: 8px;

                &:empty {
                    display: none;
                }
            }

            &-stock {
                font-size: 11px;
                line-height: 14px;
                font-weight: 400;

                &.available {
                    color: $green;
                }

                &.out-of-stock {
                    color: red;
                }
            }

            &-prices {
                margin-top: 12px;
                width: 100%;
                flex-shrink: 0;
                font-size: 14px;
                line-height: 1.4;
                display: flex;
                flex-wrap: wrap;

                + .column {
                    margin-top: 12px;
                }
            }

            .old-price {
                text-decoration: line-through;
                color: $grey-text;
                // font-weight: 400;
            }

            .current-price {
                display: flex;
                align-items: baseline;

                &:not(:first-child) {
                    margin-left: 30px;
                }

                &.color-dealer {
                    color: $orange;
                }

                span {
                    margin-left: 5px;
                }
            }

            .regular-price,
            .dealer-price {
                &:not(:first-child) {
                    margin-left: 30px;
                }
            }

            .simplebar-content {
                padding: 10px 0 20px !important;
            }

            .column {
                padding: 0;
                flex-shrink: 0;
                flex: initial;
                flex-shrink: 0;
                width: 100%;

                .columns {
                    margin-left: 0;
                    margin-right: 0;
                    margin-top: 0;
                    margin-bottom: 0;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 16px;
                    justify-content: space-between;
                }

                .column {
                    // width: initial;
                    max-width: 170px;

                    &:nth-child(1) {
                        align-self: start;
                    }

                    &:nth-child(2) {
                        align-self: end;
                        margin-left: auto;
                    }
                }
            }

            .cart-change-quantity {
                width: 100%;

                .button.is-small {
                    font-size: 28px;
                    width: 44px;
                    color: rgba($black, 0.5);
                }
            }

            .button.is-link.is-small,
            &-buy {
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 44px;
                border: 0;
                background-color: $purple;
                color: #fff;

                span {
                    margin-left: 6px;
                    font-size: 14px;
                }

                .icon {
                    margin-left: 0;
                    margin-right: 0;
                    color: #fff;
                }
            }
        }
    }

    .cart-button {
        width: 44px;
        height: 44px;

        .icon {
            width: 20px;
            height: 19px;
        }

        span {
            margin-left: 2px;
        }
    }

    .mobile-menu {
        position: fixed;
        top: 0;
        bottom: 0;
        //left: -100vw;
        width: 100vw;
        transform: translateX(-100%);
        transition: transform $duration;
        pointer-events: none;
        display: flex;
        z-index: 100;

        .cart {
            .dropdown-menu {
                display: none;
            }
        }

        .auth-user {
            .dropdown-menu {
                display: none;
            }

            .dropdown-trigger {
                .button {
                    color: #fff;

                    span {
                        max-width: 200px;
                    }
                }
            }
        }

        // .shop-compare {
        //     .dropdown-menu {
        //         // display: none !important;
        //         // .columns {
        //         //     display: flex;

        //         //     .column {
        //         //         &.is-narrow {
        //         //             flex: none;
        //         //             width: unset;

        //         //             .icon {
        //         //                 width: 39px;
        //         //                 height: 39px;
        //         //                 background: transparent url("data:image/svg+xml,%3Csvg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.4648 4.24219L4.97956 12.7275' stroke='%239E61FF' stroke-width='1.5'/%3E%3Cpath d='M4.98047 4.24219L13.4658 12.7275' stroke='%239E61FF' stroke-width='1.5'/%3E%3C/svg%3E%0A") center no-repeat;
        //         //                 background-size: 18px 17px;
        //         //                 transform: initial !important;
        //         //             }
        //         //         }
        //         //     }
        //         // }
        //     }
        // }

        &.is-open {
            transform: translateX(0%);
            pointer-events: auto;
            left: 0;
        }

        &-overlay {
            background-color: rgba($black, 0.3);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
        }

        &-body {
            width: calc(100% - 40px);
            max-width: 768px;
            position: relative;
            z-index: 2;
            overflow-y: auto;
            background-color: #fff;
            display: flex;
        }

        &-content {
            padding-bottom: 47px;
            width: 100%;
        }

        &-head {
            background-color: $purple;
            width: 100%;
            padding: 16px;
            color: #fff;
        }

        &-close {
            width: 21px;
            height: 21px;
            border: 0;
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 14px;
                height: 14px;
                color: #fff;
            }
        }

        .head-row {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            &:not(:last-child) {
                margin-bottom: 16px;
            }

            a {
                &:hover {
                    color: inherit;
                }
            }
        }

        // .logo {
        //     color: #fff;
        // }
        .auth-btn {
            display: flex;
            align-items: center;
            // color: #fff;
            font-size: 14px;
            line-height: 1.4;

            svg {
                width: 16px;
                height: 17px;
                margin-right: 6px;
            }
        }

        .langs {
            display: flex;

            a {
                font-size: 12px;
                line-height: 2.33;
                letter-spacing: 0.01em;
                opacity: 0.5;
                text-transform: uppercase;

                &:not(:last-child) {
                    margin-right: 13px;
                }

                &.active {
                    border-bottom: 1px solid currentColor;
                    opacity: 1;
                }
            }
        }

        .catalog {
            &.open {
                .catalog-dropdown {
                    transform: translateX(0%);
                    pointer-events: auto;
                    height: 100%;
                }
            }

            &-button {
                // height: 54px;
                background-color: $blue;
                height: 52px;
                padding: 0 16px;
                width: 100%;
                border: 0;
                color: #fff;
                font-size: 16px;
                line-height: 1.75;
                display: flex;
                align-items: center;
                position: relative;
                z-index: 2;

                svg {
                    &.square {
                        width: 18px;
                        height: 18px;
                        margin-right: 9px;
                    }

                    &.arrow {
                        width: 11px;
                        height: 7px;
                        transform: rotate(-90deg);
                    }
                }

                span {
                    margin-right: auto;
                    padding-top: 3px;
                }
            }

            .all-categories-return {
                margin-top: 16px;
                margin-left: 16px;
                display: flex;
                font-size: 16px;
                line-height: 1.37;
                color: $grey-text;
                display: flex;
                align-items: center;
                border: 0;
                background-color: transparent;

                svg {
                    width: 11px;
                    height: 7px;
                    transform: rotate(90deg);
                    margin-right: 8px;
                }

                span {
                    padding-top: 2px;
                }
            }

            &-dropdown {
                background-color: #fff;
                //opacity: 0;
                pointer-events: none;
                transition: transform $duration;
                position: fixed;
                top: 0;
                left: 0;
                z-index: 2;
                width: calc(100% - 40px);
                max-width: 768px;
                padding-bottom: 24px;
                overflow-y: auto;
                transform: translateX(-100%);

                .catalog {
                    &-button {
                        svg {
                            // &.square {
                            //     width: 18px;
                            //     height: 18px;
                            // }
                            &.arrow {
                                // width: 11px;
                                // height: 7px;
                                transform: rotate(90deg);
                            }
                        }
                    }
                }

                &-types {
                    position: relative;
                    padding: 0 16px;

                    &::after {
                        display: block;
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        border-bottom: 2px solid $grey-border;
                        pointer-events: none;
                    }
                }

                &-type {
                    padding: 16px 0;
                    line-height: 1.4;
                    color: $black;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    transition: color $duration, background-color $duration;
                    text-transform: uppercase;

                    &:not(:last-child) {
                        border-bottom: 2px solid $grey-border;
                    }
                }

                &-category {
                    padding: 0 16px;
                    // &:hover {
                    //     .catalog-dropdown-category-list {
                    //         display: flex;
                    //     }

                    //     .catalog-dropdown-category-title {
                    //         color: $purple;
                    //         background-color: $purple-ultralight;

                    //         .catalog-link-arrow {
                    //             display: block;
                    //         }

                    //         &.is-arrowless {
                    //             .catalog-link-arrow {
                    //                 display: none;
                    //             }
                    //         }
                    //     }
                    // }
                    &.is-arrowless {
                        .catalog-link-arrow {
                            display: none;
                        }
                    }

                    &-title {
                        padding: 16px 0;
                        line-height: 1.4;
                        color: $black;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        transition: color $duration, background-color $duration;
                        border-bottom: 2px solid $grey-border;

                        span {
                            padding-right: 2rem;
                        }

                        &:not(:last-child) {
                            &:hover {
                                + .catalog-dropdown-category-list {
                                    // @include not-touch {
                                    @include desktop {
                                        opacity: 1;
                                        left: 0;
                                        right: 40px;
                                        pointer-events: auto;
                                    }
                                }
                            }
                        }

                        &.has-text-link {
                            color: $purple !important;
                            background-color: $purple-ultralight;

                            padding: 16px 16px;
                            margin-top: -2px;
                            margin-left: -16px;
                            margin-right: -16px;
                        }

                        .catalog-link-arrow {
                            // display: none;
                            color: $black;
                            width: 7px;
                            height: 12px;
                        }
                    }

                    &-list {
                        // display: none;
                        pointer-events: none;
                        position: fixed;
                        top: 0;
                        bottom: 0;
                        width: 100%;
                        max-width: 768px;
                        left: 0;
                        right: 40px;
                        transition: opacity $duration, transform $duration;
                        background-color: #fff;
                        z-index: 3;
                        overflow-y: auto;
                        transform: translateX(-100%);

                        // &:hover {
                        //     opacity: 1;
                        //     left: 0;
                        //     right: 40px;
                        //     pointer-events: auto;
                        // }
                        &.is-open {
                            left: 0;
                            right: 40px;
                            pointer-events: auto;
                            transform: translateX(0%);
                            outline: 1000px solid #fff;
                        }
                    }

                    &-links {
                        overflow-y: auto;
                        padding: 0 16px;
                    }

                    &-link {
                        line-height: 1.4;
                        padding: 16px 0;
                        display: block;

                        &.head-link {
                            color: $purple;
                            text-transform: uppercase;
                        }

                        &:not(:last-child) {
                            border-bottom: 2px solid $grey-border;
                        }

                        small {
                            font-size: inherit;
                            color: $grey-text;
                            margin-left: 3px;
                        }

                        @include mobile {
                            span {
                                pointer-events: none;
                            }
                        }
                    }

                    // &-banner {
                    //     padding: 20px;
                    //     width: 300px;
                    //     flex-shrink: 0;
                    // }
                }

                &-download {
                    margin-top: 24px;
                    padding: 4px 16px;
                    line-height: 28px;
                    color: $purple;
                    display: flex;
                    align-items: center;
                    transition: color $duration, background-color $duration;
                    text-transform: uppercase;

                    &:hover {
                        color: $purple;
                        background-color: $purple-ultralight;
                    }

                    span {
                        margin-left: 5px;
                    }
                }

                .nested.dropdown {
                    &:not(:last-child) {
                        border-bottom: 2px solid $grey-border;
                    }

                    .dropdown-trigger {
                        .navbar-item {
                            line-height: 1.4;
                            padding: 16px 0;
                            display: block;
                            color: inherit;

                            &.head-link {
                                color: $purple;
                                text-transform: uppercase;
                            }
                        }
                    }

                    .dropdown-menu {
                        display: none !important;
                    }
                }
            }
        }

        &-controls {
            margin-top: 24px;
            width: 100%;

            .control-group {
                padding: 0 16px;

                &:not(:last-child) {
                    margin-bottom: 24px;
                }
            }

            .button {
                width: 100%;
                font-size: 14px;
                line-height: 1.4;
                height: auto;
                color: inherit;
            }

            .control-button {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: 0;
                background-color: transparent;
                width: 100%;
                height: auto;

                .icon {
                    width: 24px;
                    height: 24px;
                }

                .text {
                    margin-left: 4px;
                    line-height: 1.4;
                    margin-right: auto;
                    padding-top: 3px;
                    font-size: 14px;
                }

                .stats {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: 20px;
                    height: 20px;
                    color: #fff;
                    border-radius: 50%;
                    background-color: $purple-medium;
                    font-size: 12px;
                    line-height: 1;
                    font-weight: 400;
                    padding: 0 3px;
                    padding-top: 2px;
                }
            }

            .icon {
                &:first-child:not(:last-child) {
                    margin: 0;
                }
            }

            .shop-compare {
                svg {
                    width: 18px;
                    height: 18px;
                }
            }

            .wishlist-button {
                svg {
                    width: 12px;
                    height: 18px;
                }
            }

            .cart {
                svg {
                    width: 20px;
                    height: 19px;
                }
            }

            .help-button {
                color: $purple;

                .phone {
                    width: 18px;
                    height: 18px;
                }

                .arrow {
                    width: 11px;
                    height: 7px;
                    transform: rotate(-90deg);
                }
            }

            .dropdown {
                width: 100%;
            }

            .dropdown-trigger {
                width: 100%;
            }
        }

        .return-blue-button {
            // background-color: $blue;
            // height: 52px;
            // padding: 0 16px;
            // width: 100%;
            // border: 0;
            // color: #fff;
            // font-size: 16px;
            // line-height: 1.75;
            // display: flex;
            // align-items: center;
            // position: relative;
            // z-index: 2;
            margin-bottom: 8px;
            // svg {
            //     &.square {
            //         width: 18px;
            //         height: 18px;
            //         margin-right: 9px;
            //     }
            //     &.phone {
            //         width: 18px;
            //         height: 18px;
            //         margin-right: 7px;
            //     }
            //     &.arrow {
            //         width: 11px;
            //         height: 7px;
            //         transform: rotate(90deg);
            //     }
            // }
            // span {
            //     margin-right: auto;
            //     padding-top: 3px;
            // }
        }

        .mobile-menu-help {
            background-color: #fff;
            opacity: 0;
            pointer-events: none;
            transition: opacity $duration, left $duration;
            position: fixed;
            top: 0;
            bottom: 0;
            z-index: 4;
            left: -100%;
            width: calc(100% - 40px);
            max-width: 768px;
            padding-bottom: 24px;
            overflow-y: auto;

            &.is-open {
                opacity: 1;
                pointer-events: auto;
                left: 0;
            }
        }

        // .help-group-return {
        // }

        .help-link {
            padding: 8px 16px;
            font-size: 16px;
            line-height: 1.4;
            display: flex;
            align-items: center;
            color: $black;

            &:not(:last-child) {
                margin-bottom: 8px;
            }

            .icon-wrapper {
                width: 24px;
                height: 24px;
                margin-right: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            svg {
                color: $grey-text;

                &.phone {
                    width: 18px;
                    height: 18px;
                }

                &.mail {
                    width: 20px;
                    height: 16px;
                }

                &.chat {
                    width: 20px;
                    height: 20px;
                }

                &.messenger {
                    width: 22px;
                    height: 22px;
                }
            }
        }

        .site-menu {
            margin: 16px 16px 0;
            border-top: 2px solid $grey-border;
            padding-top: 16px;
            max-width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &-link {
                line-height: 2;
                font-weight: 400;
                color: rgba(#000, 0.5);
                &.is-highlighted {
                    color: $purple;
                }
                &:not(:last-child) {
                    margin-bottom: 4px;
                }
            }
        }
    }

    .compare-menu {
        position: fixed;
        top: 0;
        bottom: 0;
        width: 100vw;
        transform: translateX(-100%);
        transition: transform $duration;
        pointer-events: none;
        display: flex;
        z-index: 100;

        &.is-open {
            transform: translateX(0%);
            pointer-events: auto;
            left: 0;
        }

        &-overlay {
            background-color: rgba($black, 0.3);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
        }

        &-body {
            width: calc(100% - 40px);
            max-width: 768px;
            position: relative;
            z-index: 2;
            overflow-y: auto;
            background-color: #fff;
            display: flex;
        }

        &-content {
            padding-bottom: 47px;
            width: 100%;
        }

        .return-blue-button {
            span:not(.compare-amount) {
                margin-right: 8px;
            }
        }

        .compare-amount {
            margin-right: auto;
            background-color: $purple-medium;
            font-size: 12px;
            // line-height: 17px;
            line-height: 1;
            border-radius: 50%;
            min-width: 17px;
            height: 17px;
            // padding: 0 2px;
            padding: 0 3px;
            padding-top: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }

        &-title {
            padding: 16px 16px 0;
            font-size: 14px;
            line-height: 1.4;
            text-transform: uppercase;
            color: $grey;
        }

        &-list {
            padding: 12px 16px;
        }

        .compare-item {
            display: flex;
            // align-items: flex-start;
            align-items: center;
            padding: 12px 0;
            border-bottom: 2px solid $grey-border;
            width: 100%;

            &-category {
                font-size: 14px;
                line-height: 1.4;
                margin-right: auto;

                span {
                    color: $grey-text;
                }
            }

            &-remove {
                width: 17px;
                height: 17px;
                background-image: url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.1875 7.68761L11 12.5001L12.0607 11.4395L7.24816 6.62695L12.375 1.50011L11.3143 0.439453L6.1875 5.56629L1.06066 0.439453L0 1.50011L5.12684 6.62695L0.31434 11.4395L1.375 12.5001L6.1875 7.68761Z' fill='%23909090'/%3E%3C/svg%3E%0A");
                background-size: 13px 13px;
                border: 0;
                background-color: transparent;
                background-position: center;
                background-repeat: no-repeat;
                margin-left: 16px;
                flex-shrink: 0;
            }
        }
    }
}
