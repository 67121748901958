$family-primary: 'BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif';
$title-font: 'TT Firs Neue';

$grey-bg: #f5f5f5;
$grey-border: #eee;
$grey-text: #909090;
$grey-text-2: #bdbdbd;

$black: #222;
$blue: #8539fd;
$blue-2: #5f88ff;

$purple: #9e61ff;
$purple-medium: #b98cff;
$purple-light: #ecdfff;
$purple-ultralight: #f5efff;

$orange: #ffa825;
$orange-2: #ffbf17;
$sand: #fff3d8;

$green: #27ae34;
$green-2: #5ae157;

$red: #e23839;
$warning: #ff2525;
$red-2: #ff8c8c;

$duration: 0.3s;

$wrap-inner-width: 1440px;
$wrap-padding-x: 32px;
