* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
}

body {
    font-family: 'Brutal Type';
    font-weight: 500;
    color: $black;
    font-size: 14px;

    &.grey-bg {
        background-color: $grey-bg;
    }
}

button,
input,
optgroup,
select,
textarea {
    font-family: 'Brutal Type';
}

button {
    cursor: pointer;
}

a {
    color: inherit;
}

.wrapper {
    display: flex;
    flex-direction: column;
    // min-height: 100vh;
    // min-height: -webkit-fill-available;
    // overflow: hidden;

    &:first-of-type {
        padding-top: 114px;
    }

    &.simple-wrapper {
        padding-top: 0;
    }
}

.wrapper + .wrapper {
    margin-top: 40px;
}

main {
    flex-grow: 1;
}

.container.is-fluid {
    max-width: 1440px !important;
    margin-left: auto;
    margin-right: auto;
    background-color: transparent;

    @include maxw1280 {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.cart-change-quantity {
    flex-shrink: 0;
    display: flex;
    width: 84px;
    height: 44px;
    border: 1px solid $grey-border;
    position: relative;
    @include mobile {
        width: 92px;
        height: 36px;
    }

    .button {
        color: rgba($black, 0.7);
        width: 30px;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        // font-size: 18px;
        font-weight: 400;
        padding: 0;
        border: 0;
        background: transparent;
        height: auto;

        &.is-small {
            font-size: 18px;
            @include mobile {
                font-size: 28px;
            }
        }

        &:first-child {
            left: 0;
        }

        &:last-child {
            right: 0;
        }

        &:focus {
            outline: none;
            box-shadow: initial;
        }
    }

    .input {
        line-height: 42px;
        appearance: textfield;
        color: $black;
        border: 0;
        text-align: center;
        font-weight: 400;
        display: block;
        width: 100%;
        height: 100% !important;
        box-shadow: initial;
        border-radius: 0;
        padding: 0 15px !important;
        font-size: 14px;
        font-weight: 500;

        &::placeholder {
            color: inherit;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}

.block {
    position: relative;
    z-index: 2;

    // &:not(:first-child) {
    &:not(:first-of-type) {
        // &:not(.block-home-slider) {
        margin-top: 80px;

        @include mobile {
            margin-top: 60px;
        }
    }

    &:not(:last-child) {
        margin-bottom: 0;
    }

    .breadcrumb {
        padding-top: 30px;
        // padding-top: 10px;
        margin-bottom: 42px;

        @include mobile {
            padding-top: 24px;
            margin-bottom: 16px;
        }

        ul {
            @include mobile {
                flex-wrap: wrap;
            }
        }
    }

    .block-head {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        position: relative;
        z-index: 2;
        width: 100%;

        &-link {
            font-size: 12px;
            line-height: 28px;
            text-transform: uppercase;
            color: $purple;
            flex-shrink: 0;
        }

        .discount-title {
            font-size: 14px;
            line-height: 1.57;
            align-self: center;

            span {
                color: $warning;
            }
        }
    }

    .block-title {
        font-family: $title-font;
        font-size: 40px;
        // font-weight: 400;
        font-weight: 500;
        line-height: 1.2;
        // width: 100%;
        margin-right: auto;

        @include maxw1200 {
            font-size: 36px;
        }

        @include touch {
            font-size: 32px;
        }

        @include mobile {
            font-size: 24px;
            line-height: 1.3;
        }

        span {
            &.is-active {
                color: $purple;
                border-bottom: 3px dotted $purple;
            }

            &.grey {
                color: $grey-text;

                @include mobile {
                    font-size: 20px;
                }
            }
        }

        + .swiper-container {
            margin-top: 80px;

            @include mobile {
                margin-top: 24px;
            }
        }

        .dropdown {
            &.is-active {
                .title-drop-triangle {
                    transform: rotate(-180deg);
                }
            }
        }

        .dropdown-trigger {
            color: $purple;
            cursor: pointer;
            position: relative;

            div {
                display: flex;
                align-items: center;
            }

            span {
                border-bottom: 2px dotted currentColor;
                // margin-right: 9px;
                padding-right: 20px;
            }
        }

        .dropdown-menu {
            left: -20px;
            right: -20px;
            padding-top: 0;

            @include mobile {
                left: 0;
            }

            &::before {
                display: block;
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 5px 6px 5px;
                border-color: transparent transparent #fff transparent;
                position: absolute;
                bottom: 100%;
                right: 20px;
            }
        }

        .dropdown-content {
            background-color: initial;
            border: 0;
            padding: 0;
            box-shadow: 0;
            padding: 5px 0;
            background-color: #fff;
            box-shadow: 0px 1px 5px rgba(34, 34, 34, 0.15);
            display: flex;
            flex-direction: column;
        }

        .dropdown-item {
            height: 40px;
            line-height: 40px;
            padding: 0 15px;
            font-size: 14px;
            line-height: 17px;
            color: #000;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: 'Brutal Type';
            font-weight: 500;

            &:hover {
                background-color: $purple-light;
                color: $purple;
                transition: color $duration, background-color $duration;
            }

            &.is-active {
                color: $purple;
                pointer-events: none;
                background-color: #fff;

                &::after {
                    display: block;
                    content: '';
                    background-image: url("data:image/svg+xml,%3Csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.45455 9.20149L1.11364 5.75373L0 6.90299L4.45455 11.5L14 1.64925L12.8864 0.5L4.45455 9.20149Z' fill='%239E61FF'/%3E%3C/svg%3E%0A");
                    width: 14px;
                    height: 12px;
                    margin-left: 10px;
                }
            }
        }

        .title-drop-triangle {
            width: 11px;
            height: 8px;
            // position: relative;
            position: absolute;
            right: 0;
            // top: -4px;
            top: 50%;
            transition: transform $duration;
            pointer-events: none;

            @include mobile {
                top: calc(50% - 2px);
            }
        }
    }

    .tabs {
        margin-top: 24px;
        overflow: initial;
        width: 100%;

        &::-webkit-scrollbar {
            @include mobile {
                display: none;
            }
        }

        @include mobile {
            // overflow: auto;
            overflow: scroll;
            margin-left: -10px;
            margin-right: -10px;
            width: calc(100% + 20px);
            scrollbar-width: none;
        }

        &:not(:last-child) {
            margin-bottom: 0;
        }

        ul {
            border: 0;

            @include mobile {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        li {
            @include mobile {
                padding-bottom: 19px;
            }

            &:not(:last-child) {
                margin-right: 24px;
            }

            &.is-active {
                .a {
                    color: $black;
                    pointer-events: none;

                    &:after {
                        content: '';
                    }
                }
            }
        }

        .a {
            padding: 0;
            margin: 0;
            border: 0;
            font-size: 14px;
            line-height: 1;
            position: relative;
            color: $grey-text;

            &::after {
                display: block;
                position: absolute;
                border-bottom: 2px solid currentColor;
                left: 0;
                right: 0;
                top: calc(100% + 17px);
                pointer-events: none;
            }
        }
    }

    .tabs-content {
        margin-top: 42px;

        @include mobile {
            // margin-top: 36px;
            margin-top: 18px;
            margin-left: -10px;
            margin-right: -10px;
        }
    }

    .tab {
        @include mobile {
            padding-left: 10px;
            padding-right: 10px;
        }

        // .swiper-slide {
        //     @include mobile {

        //     }
        // }
        .swiper-button {
            &-prev,
            &-next {
                @include mobile {
                    display: none;
                }
            }
        }
    }

    // .swiper-container-wrapper {
    //     position: relative;
    // }
    // .swiper-container {
    //     overflow: hidden;
    // }
    .five-items-slider {
        padding-left: 1px;
        padding-right: 1px;

        .card-inner {
            margin-left: -1px;
            margin-right: -1px;
            width: calc(100% + 2px);
        }
    }

    .swiper-slide {
        @include mobile {
            width: initial;
            height: initial;
        }

        &:not(:last-child) {
            .card.product {
                .card-inner {
                    @include mobile {
                        border-right: 0;
                    }
                }
            }
        }

        .card.product {
            @include mobile {
                width: 268px;
                min-height: 404px;
            }
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    .swiper-button {
        &-prev,
        &-next {
            width: 42px;
            height: 42px;
            transform: initial !important;
            // top: calc(50% - 21px);
            top: 50%;
            border-radius: 50%;
            background-color: #fff;
            box-shadow: -1px 1px 5px rgba(34, 34, 34, 0.2);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 11px 17px;
            z-index: 2;
            transition: background-color $duration, opacity $duration;
            border: 0;

            &::after {
                content: none;
            }
        }

        &-prev {
            background-image: url("data:image/svg+xml,%3Csvg width='11' height='17' viewBox='0 0 11 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.4199 2.16118L4.26905 8.31205L10.4199 14.4629L8.52171 16.3612L0.472553 8.31205L8.52171 0.262901L10.4199 2.16118Z' fill='%23222222'/%3E%3C/svg%3E%0A");
            left: -19px;
            right: initial;

            @include responsive {
                left: 0;
            }
        }

        &-next {
            background-image: url("data:image/svg+xml,%3Csvg width='11' height='17' viewBox='0 0 11 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.580078 2.16118L6.73095 8.31205L0.580078 14.4629L2.47829 16.3612L10.5274 8.31205L2.47829 0.262901L0.580078 2.16118Z' fill='%23222222'/%3E%3C/svg%3E%0A");
            right: -19px;

            @include responsive {
                right: 0;
            }
        }

        &-disabled {
            display: none !important;
            // opacity: .5;
            // pointer-events: none;
        }
    }

    // .card.product {
    //     // max-width: 275px;
    // }

    .table {
        width: 100%;
    }
}

.return-blue-button {
    background-color: $blue;
    height: 52px;
    padding: 0 16px;
    width: 100%;
    border: 0;
    color: #fff;
    font-size: 16px;
    line-height: 1.75;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;

    // margin-bottom: 8px;
    svg {
        &.square {
            width: 18px;
            height: 18px;
            margin-right: 9px !important;
        }

        &.phone {
            width: 18px;
            height: 18px;
            margin-right: 7px !important;
        }

        &.filter {
            width: 15px;
            height: 18px;
            margin-right: 10px !important;
        }

        &.arrow {
            width: 11px;
            height: 7px;
            transform: rotate(90deg);
        }
    }

    span {
        margin-right: auto;
        padding-top: 3px;
    }
}

.is-background-white {
    background-color: #fff;
}

.is-skeleton {
    pointer-events: none;
    position: relative;

    .block-title {
        background: #eee;
        width: 300px;
    }

    .card-number {
        background: #eee;
        width: 100px;
    }

    .current-price {
        background: #eee;
        width: 40px;
    }

    .card-title {
        width: 70%;
        background: #eee;
    }

    .tabs {
        li {
            background: #eee;
            width: 150px;
        }
    }

    .gradient {
        z-index: 100;
        mix-blend-mode: screen;
        opacity: 0.8;
        height: 100%;
        width: 100%;
        position: absolute;
        inset: 0;
        background: linear-gradient(130deg, #000000, rgba(#ffffff, 0.75), #000000);
        background-size: 200% 200%;

        -webkit-animation: Animation 5s ease infinite;
        -moz-animation: Animation 5s ease infinite;
        animation: Animation 5s ease infinite;
    }

    @-webkit-keyframes Animation {
        0% {
            background-position: 10% 0%;
        }

        50% {
            background-position: 91% 100%;
        }

        100% {
            background-position: 10% 0%;
        }
    }

    @-moz-keyframes Animation {
        0% {
            background-position: 10% 0%;
        }

        50% {
            background-position: 91% 100%;
        }

        100% {
            background-position: 10% 0%;
        }
    }

    @keyframes Animation {
        0% {
            background-position: 10% 0%;
        }

        50% {
            background-position: 91% 100%;
        }

        100% {
            background-position: 10% 0%;
        }
    }
}
