@font-face {
    font-family: "Brutal Type";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/BrutalType-Regular.woff2") format("woff2");
    font-display: swap;
}
@font-face {
    font-family: "Brutal Type";
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/BrutalType-Medium.woff2") format("woff2");
    font-display: swap;
}
@font-face {
    font-family: "Brutal Type";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/BrutalType-Bold.woff2") format("woff2");
    font-display: swap;
}
/*
@font-face {
    font-family: "GT Walsheim Pro";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/GTWalsheimPro-Bold.woff2") format("woff2");
    font-display: swap;
}

@font-face {
    font-family: "SF Compact Display";
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/SFCompactDisplay-Medium.woff2") format("woff2");
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/SFProDisplay-Regular.woff2") format("woff2");
    font-display: swap;
}
@font-face {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/SFProDisplay-Medium.woff2") format("woff2");
    font-display: swap;
}
@font-face {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 600;
    src: url("../fonts/SFProDisplay-Semibold.woff2") format("woff2");
    font-display: swap;
}
@font-face {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/SFProDisplay-Dold.woff2") format("woff2");
    font-display: swap;
}
@font-face {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 800;
    src: url("../fonts/SFProDisplay-Heavy.woff2") format("woff2");
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/SFProText-Light.woff2") format("woff2");
    font-display: swap;
}
@font-face {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/SFProText-Regular.woff2") format("woff2");
    font-display: swap;
}
*/
@font-face {
    font-family: "TT Firs Neue";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/TTFirsNeue-Regular.woff2") format("woff2");
    font-display: swap;
}
@font-face {
    font-family: "TT Firs Neue";
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/TTFirsNeue-Medium.woff2") format("woff2");
    font-display: swap;
}
@font-face {
    font-family: "TT Firs Neue";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/TTFirsNeue-Bold.woff2") format("woff2");
    font-display: swap;
}